<template>
  <div>
    <h5> Next Issue In Queue</h5>
    <div class="" style="margin-top: 6px; max-height: 200px; overflow-x: hidden; overflow-y: auto;">
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <template v-if="!$isEmpty(issuesData)">
          <div v-for="issueSidebar in issuesData" :key="issueSidebar.key">
            <b-row
              v-if="!$isEmpty(issueSidebar)"
              :class="`m-0 cursor-pointer w-100 ${ issueSidebar._id === currentSelectedIssue ? 'bg-light-primary' : '' }`"
              style="padding: 2px;"
              @click="goToNextIssue(issueSidebar._id)"
            >
              <!-- <div class="m-0 p-0 d-flex align-items-center text-center" style="width: 15%;">
                <b-avatar
                  size="38"
                  variant="light-primary"
                  :src="issueSidebar.user.image"
                  class="mx-auto badge-minimal"
                />
              </div> -->
              <div class="" style="width: 80%;">
                <div class="m-0 p-0 d-flex-inline align-items-between w-100">
                  <b class="m-0 p-0">
                    {{ issueSidebar.user.fullname }}
                  </b>
                </div>
                <small class="m-0 p-0">
                  {{ issueSidebar.subject }}
                </small>
              </div>
              <div class="d-flex align-items-center">
                <b-badge
                  pill
                  :class="`bg-${statusBadgeColor(issueSidebar.status)}`"
                >
                  <span>{{ $capitalizeString(issueSidebar.status) }}</span>
                </b-badge>
              </div>
            </b-row>
          </div>
        </template>
        <template v-else>
          <div class="w-100 pt-2">
            <div class="h-100 text-center">
              <h6>
                No Open Issues
              </h6>
            </div>
          </div>
        </template>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  debounce,
} from 'lodash'

export default {
  props: {
    currentSelectedIssue: { type: String, default: '' },
    issue: { type: Object, default: () => {} },
  },
  data() {
    return {
      isLoading: false,
      issuesData: [],

      issueSearchQuery: '',

      refreshIssuesInterval: null,
      refreshNextQueuedIssue: null,

      filters: {},
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 3,
      },
    }
  },
  computed: {
    statusBadgeColor() {
      return value => {
        if (value === 'open') return 'success'
        if (value === 'closed') return 'danger'
        if (value === 'urgent') return 'warning'
        return 'primary'
      }
    },
  },
  watch: {
    // issueSearchQuery(val, oldVal) {
    //   this.isLoading = true
    //   debounce(params => {
    //     this.get_issues()
    //   }, 2000)()
    // },

    // server pagination
    'pagination.currentPage': function (val, oldVal) {
      this.get_issues()
    },
    'pagination.perPage': function (val, oldVal) {
      this.get_issues()
    },
  },
  created() {
    // this.get_issues()
    // this.refreshIssuesInterval = setInterval(() => {
    //   this.get_issues(false)
    // }, 10000)

    this.get_next_queued_issue() 
    this.refreshNextQueuedIssue = setInterval(() => {
      this.get_next_queued_issue() 
    }, 5000)
  },
  destroyed() {
    clearInterval(this.refreshIssuesInterval)
    clearInterval(this.refreshNextQueuedIssue)
  },
  methods: {
    get_next_queued_issue() {
      this.$http
        .get(`/api/help-desks/issues-queues-next`)
        .then(response => {
          this.issuesData = []
          this.issuesData.push(response.data.output.issue)
        })
        .catch(() => {})
    },
    // get_current_issue_queue_status() {
    //   this.$http
    //     .get(`/api/help-desks/issues-queues-current?with_issue=True`)
    //     .then(response => {
    //       this.currentQueue = response.data.output
    //       this.currentQueueMeta = response.data.meta
    //     })
    //     .catch(() => {})
    // },

    async get_issues(isLoading = true) {
      this.isLoading = isLoading

      let query = ''

      this.filters['status'] = { filter: 'open', filterType: 'text', type: 'contains', }
      this.filters['subject'] = { filter: this.issueSearchQuery, filterType: 'text', type: 'contains', }
      if (this.filters && Object.keys(this.filters).length > 0) {
        query += `${this.$attachQuerySymbol(query)}filters=${JSON.stringify(this.filters)}`
      }

      // if (this.sorters && Object.keys(this.sorters).length > 0) {
      //   query += `${this.$attachQuerySymbol(query)}sorters=${JSON.stringify(this.sorters)}`
      // }

      query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`

      const select_columns = ['subject', 'description', 'user_id']
      query += `${this.$attachQuerySymbol(query)}select_columns=${JSON.stringify(select_columns)}`

      this.$http
        .get(`/api/help-desks/issues${query}`)
        .then(response => {
          this.issuesData = response.data.output
          this.pagination = response.data.pagination

          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    goToNextIssue(issue_id) {
      if (this.issue.status !== 'closed') {
        this.confirmLeaveIssue(issue_id)
      } else {
        this.$emit('get-issue', issue_id)
      }
    },

    confirmLeaveIssue(issue_id) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to leave this issue?, the issue is still open`,
          {
            title: 'Confirm Leave Page',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Leave',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.$emit('get-issue', issue_id)
          }
        })
    },
  },
}
</script>

<style>

</style>