<template>
  <b-row style="height: 78vh;">
    <b-col
      col
      cols="12"
      xl="4"
      order-xl="1"
      order-lg="1"
      order-md="2"
      order-sm="2"
      order="2"
      class="h-100 mt-2 mt-md-0"
    >
      <b-card class="h-100">
        <div class="d-flex flex-wrap h-100 w-100">
          <div
            class="w-100"
            style="min-height: 35%; max-height: 65%;"
          >
            <b-row class="m-auto w-100">
              <b-col class="w-100 pr-md-1 pl-0">
                <b-button
                  class="w-100 h-100"
                  variant="primary"
                  @click="goBack"
                >Go Back</b-button>
              </b-col>
              <b-col class="w-100 pl-md-1 pr-0">
                <b-button
                  v-if="$isEmpty(issue.assigned_to) && issue.status !== 'closed'"
                  class="w-100 h-100"
                  variant="warning"
                  :disabled="$isEmpty(issue._id)"
                  @click="confirmAssignToMe"
                >Assign To Me</b-button>
                <b-button
                  v-else-if="issue.status !== 'closed'"
                  class="w-100 h-100"
                  variant="danger"
                  :disabled="issue.assigned_to !== $store.state.auth.ActiveUser.email"
                  @click="confirmCloseIssue"
                >Close Issue</b-button>
                <b-button
                  v-else
                  class="w-100 h-100"
                  variant="success"
                  :disabled="$isEmpty(issue._id)"
                  @click="confirmReopenIssue"
                >Reopen Issue</b-button>
              </b-col>
            </b-row>

            <b-row class="mt-1 mx-auto w-100">
              <b-col class="w-100 p-0">
                <b-button
                  v-if="searchNextQueuedIssue"
                  class="w-100 h-100"
                  variant="success"
                  @click="toggleSearchNextQueuedIssue"
                >Status: Online</b-button>
                <b-button
                  v-else
                  class="w-100 h-100"
                  variant="danger"
                  @click="toggleSearchNextQueuedIssue"
                >Status: Offline</b-button>
              </b-col>
            </b-row>
            <hr>

            <div class="mt-1 d-flex align-items-center">
              <b-badge
                pill
                :variant="statusBadgeColor(issue.status)"
                class="mr-1 px-1"
              >
                {{ $capitalizeString(issue.status || '') }}
              </b-badge>

              {{ issue.ticket_number }}
            </div>

            <div class="mt-1">
              <h5>Issue Subject</h5>
              <b-row>
                <b-col cols="10">
                  <template v-if="editSubject">
                    <b-input
                      v-model="issue.subject"
                    />
                  </template>
                  <template v-else>
                    <h3 class="pl-1 m-0">
                      {{ issue.subject || '-' }}
                    </h3>
                  </template>
                </b-col>
                <b-col>
                  <div class="d-flex align-items-center text-center h-100">
                    <feather-icon
                      :class="`${editSubject === true ? 'text-success' : 'text-primary'} cursor-pointer`"
                      :icon="editSubject === true ? 'CheckIcon' : 'EditIcon'"
                      size="14"
                      @click="editSubject = !editSubject"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>

            <div class="mt-1">
              <h5>Details</h5>
              <ul class="list-unstyled pl-1">
                <li class="">
                  <span class="align-middle"><b>Issue Owner: </b>{{ issue.user_id }}</span>
                </li>
                <li class="">
                  <span class="align-middle"><b>Assigned To: </b>{{ issue.assigned_to }}</span>
                </li>
                <li class="">
                  <span class="align-middle"><b>Contact Method: </b>{{ issue.contact_method }}</span>
                </li>
              </ul>
            </div>

            <div class="mt-1">
              <h5>Timestamps</h5>
              <ul class="list-unstyled pl-1">
                <li class="">
                  <span class="align-middle"><b>Start Date: </b>{{ $formatDatetime(issue.created_at, true) }}</span>
                </li>
                <li class="">
                  <span class="align-middle"><b>End Date: </b>{{ $formatDatetime(issue.closed_at, true) }}</span>
                </li>
                <li class="">
                  <span class="align-middle"><b>Duration: </b>{{ countDuration(issue.duration) }}</span>
                </li>
              </ul>
            </div>

            <div
              style="max-height: 5em;"
              class="mt-1 w-100"
            >
              <h5>Issue Screenshot</h5>
              <div
                class="container-fluid pl-1"
              >
                <b-img
                  :src="!$isEmpty(issue.attachments) ? issue.attachments[0] : `https://i.imgur.com/CJ28yzb.png`"
                  class="my-auto img-fluid border border-primary"
                  style="height: 4em; width: auto;"
                  @click="showPreviewScreenshot = !showPreviewScreenshot"
                />
              </div>
            </div>
          </div>

          <div class="w-100 mt-auto">
            <left-pane-chat-queue
              class="mt-auto"
              :issue="issue"
              :current-selected-issue="currentSelectedIssue"
              @get-issue="get_issue($event)"
            />
          </div>
        </div>
      </b-card>
    </b-col>

    <b-col
      col
      cols="12"
      xl="5"
      order-xl="2"
      order-lg="2"
      order-md="1"
      order-sm="1"
      order="1"
      class="h-100 mt-2 mt-xl-0 mt-md-0"
    >
      <b-overlay
        :show="searchNextQueuedIssue"
        class="h-100"
      >
        <!-- <ChatBoxTelegram
          v-if="issue.contact_method === 'telegram'"
        /> -->

        <ChatBox
          :issue="issue"
          :issue-chats="issueChats"
          :sender="sender"
          :recipient="recipient"
          :is-chat-loading="isChatLoading"
          @toggle-user-details="showUserDetails = !showUserDetails"
          @refresh-chats="get_chats"
          @refresh-issue="get_issue"
          @start-search-next-queued-issue="searchNextQueuedIssue = !searchNextQueuedIssue"
        />

        <template #overlay>
          <div class="w-100 text-center">
            <b-spinner />
          </div>
          <div class="text-center mt-2">
            Searching...
          </div>
        </template>
      </b-overlay>
    </b-col>

    <b-col
      v-if="showUserDetails"
      col
      cols="12"
      xl="3"
      order-xl="3"
      order-lg="3"
      order-md="3"
      order-sm="3"
      order="3"
      class="h-100 mt-2 mt-xl-0 mt-md-0"
    >
      <user-details-card
        :user="recipient"
        :temporary-contact-details="temporaryContactDetails"
        @updated-temporary-contact-details="updatedTemporaryContactDetails"
      />
    </b-col>

    <!-- view issue screenshot modal -->
    <b-modal
      v-model="showPreviewScreenshot"
      size="lg"
      centered
      hide-footer
    >
      <b-img
        :src="!$isEmpty(issue.attachments) ? issue.attachments[0] : `https://i.imgur.com/CJ28yzb.png`"
        class="img-fluid"
      />
    </b-modal>
  </b-row>
</template>

<script>
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { helpDesksMixins } from '../mixins/helpDesksMixins'
// import { debounce } from 'lodash'

import LeftPaneChatQueue from '../components/LeftPaneChatQueue.vue'
import ChatBox from '../components/ChatBox.vue'
import ChatBoxTelegram from '../components/ChatBoxTelegram.vue'
import UserDetailsCard from '../components/UserDetailsCard.vue'

export default {
  components: {
    LeftPaneChatQueue,
    ChatBox,
    ChatBoxTelegram,
    UserDetailsCard,
    FeatherIcon,
  },
  mixins: [helpDesksMixins],
  data() {
    return {
      isChatLoading: false,
      currentSelectedIssue: this.$route.params.id,
      issue: {
        chats: [],
      },
      issueChats: [],
      previousIssueChatsCount: 0,

      searchNextQueuedIssue: false,

      refreshIssueInterval: null,
      refreshChatsInterval: null,
      refreshSearchNextQueuedIssueInterval: null,

      sender: {},
      recipient: {},

      showUserDetails: true,
      showPreviewScreenshot: false,

      temporaryContactDetails: null, // for issues that was created from telegram

      editSubject: false,
    }
  },
  watch: {
    searchNextQueuedIssue(val) {
      if (val) {
        clearInterval(this.refreshSearchNextQueuedIssueInterval)

        this.refreshSearchNextQueuedIssueInterval = setInterval(() => {
          this.get_next_queued_issue()
        }, 2000)

        setTimeout(() => {
          this.currentSelectedIssue = null
          this.temporaryContactDetails = {}
          this.issue = {}
          this.issueChats = []
          this.sender = {}
          this.recipient = {}
        }, 2050)
      } else {
        clearInterval(this.refreshSearchNextQueuedIssueInterval)
        this.get_chats()
      }
    },
    editSubject(val) {
      if (val === false) {
        this.put_issue()
        this.startRefreshIssue()
      } else {
        this.stopRefreshIssue()
      }
    },
  },
  created() {
    if (!this.$isEmpty(this.$route.params.id)) {
      this.startRefreshIssue()
      // } else {
      //   this.searchNextQueuedIssue = true
    }
  },
  destroyed() {
    clearInterval(this.refreshIssueInterval)
    clearInterval(this.refreshChatsInterval)
    clearInterval(this.refreshSearchNextQueuedIssueInterval)
  },
  methods: {
    get_next_queued_issue() {
      this.$http
        .get('/api/help-desks/issues-queues-next?assign=True')
        .then(response => {
          const { success, output } = response.data
          if (success && !this.$isEmpty(output)) {
            this.currentSelectedIssue = output.issue_id
            if (!this.$isEmpty(this.currentSelectedIssue)) {
              this.startRefreshIssue()

              this.searchNextQueuedIssue = false
            }
          }
        })
        .catch(() => {})
    },
    get_issue(id = null) {
      if (id !== null && id !== true) {
        this.currentSelectedIssue = id
      }

      if (!this.$isEmpty(this.currentSelectedIssue)) {
        this.$http
          .get(`/api/help-desks/issues/${this.currentSelectedIssue}`)
          .then(response => {
            this.issue = response.data.output

            if (this.issue.total_chat_counter !== this.issue.chat_seen_tracker[this.$store.state.auth.ActiveUser.email]) {
              this.get_chats()

              this.put_chat_seen_tracker()

              // this.get_sender()
              this.get_recipient()
            }

            if (
              !this.$isEmpty(this.issue.temporary_contact_details_id)
              && this.$isEmpty(this.temporaryContactDetails)
            ) {
              this.get_help_desks_temporary_contact_details(this.issue.temporary_contact_details_id)
            }
          })
          .catch(() => {})
      }
    },
    startRefreshIssue() {
      clearInterval(this.refreshIssueInterval)
      this.refreshIssueInterval = setInterval(() => {
        this.get_issue()
      }, 2000)
      this.get_chats()
    },
    stopRefreshIssue() {
      clearInterval(this.refreshIssueInterval)
    },
    get_chats(isChatLoading = true) {
      this.isChatLoading = isChatLoading

      // if (this.issue.contact_method === 'telegram') {
      //   this.$http
      //     .get(`/api/help-desks/issues/${this.currentSelectedIssue}/telegram-chats`)
      //     .then(response => {
      //       this.issue.chats = response.data.output
      //       this.issueChats = response.data.output
      //       console.log(`🚀 ~ file: View.vue ~ line 305 ~ get_chats ~ this.issueChats`, this.issueChats)

      //       this.isChatLoading = false
      //     })
      //     .catch(() => {
      //       this.isChatLoading = false
      //     })
      // } else {
      if (!this.$isEmpty(this.currentSelectedIssue)) {
        this.$http
          .get(`/api/help-desks/issues/${this.currentSelectedIssue}/chats`)
          .then(response => {
            this.issue.chats = response.data.output
            this.issueChats = response.data.output

            this.isChatLoading = false
            if (this.previousIssueChatsCount !== this.issueChats.length) {
              this.put_chat_seen_tracker()
              this.previousIssueChatsCount = this.issueChats.length

              // this.get_issue()
            }
          })
          .catch(() => {
            this.isChatLoading = false
          })
      }

      // }
    },
    get_sender() {
      this.sender = this.$store.state.auth.ActiveUser
    },
    get_recipient() {
      const currentUser = this.$store.state.auth.ActiveUser.email
      this.recipient.email_address = this.issue.user_id
      if (currentUser === this.issue.user_id && !this.$isEmpty(this.issue.assigned_to)) {
        // ! currently defaulting to the sender
        this.recipient.email_address = this.issue.assigned_to
      }

      this.$http
        .get(`/api/users/${this.recipient.email}`)
        .then(response => {
          this.recipient = response.data
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    put_chat_seen_tracker() {
      this.$http
        .put(`/api/help-desks/issues/${this.currentSelectedIssue}/update-chat-seen-tracker`)
        .then(response => {
          // console.log(`🚀 ~ file: View.vue ~ line 232 ~ put_chat_seen_tracker ~ response`, response)
        })
        .catch(() => {})
    },

    confirmAssignToMe() {
      this.$bvModal
        .msgBoxConfirm(
          `Assign "${this.issue.subject}" to yourself?`,
          {
            title: 'Confirm User Deletion',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Assign to Me',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.assignToMe()
          }
        })
    },
    assignToMe() {
      const form_data = this.issue
      form_data.assigned_to = this.$store.state.auth.ActiveUser.email

      this.$http
        .put(`/api/help-desks/issues/${this.currentSelectedIssue}/assign`, {
          form_data,
        })
        .then(response => {
          this.get_issue()
          this.get_chat()
        })
        .catch(() => {})
    },

    confirmCloseIssue() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to close this issue? "${this.issue.subject}"`,
          {
            title: 'Confirm Issue Closing',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Close Issue',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.closeIssue()
              .then(() => {
              })
          }
        })
    },
    closeIssue() {
      this.$http
        .delete(`/api/help-desks/issues/${this.currentSelectedIssue}/close`)
        .then(response => {
          const { success, output } = response.data

          if (success) {
            this.searchNextQueuedIssue = true

            this.issue = {}
            this.issueChats = []
            this.recipient = {}
            this.sender = {}
            this.currentSelectedIssue = null
            this.temporaryContactDetails = {}
          }

          // this.get_issue()
        })
        .catch(() => {
        })
    },

    confirmReopenIssue() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to Reopen this issue? "${this.issue.subject}"`,
          {
            title: 'Confirm Issue Reopening',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Reopen Issue',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.reopenIssue()
          }
        })
    },
    reopenIssue() {
      this.$http
        .put(`/api/help-desks/issues/${this.currentSelectedIssue}/open`)
        .then(response => {
          this.get_issue()
          this.get_chat()
        })
        .catch(() => {})
    },

    goBack() {
      if (window.history.length <= 1) {
        window.close()
      } else {
        this.$router.go(-1)
      }
    },

    get_help_desks_temporary_contact_details(id) {
      if (!this.$isEmpty(id)) {
        this.$http
          .get(`/api/help-desks/issues-temporary-contact-details/${id}`)
          .then(response => {
            this.issue = {}
            this.issueChats = []
            this.get_issue()

            this.temporaryContactDetails = {}
            this.temporaryContactDetails = response.data.output
          })
          .catch(() => {})
      }
    },
    updatedTemporaryContactDetails() {
      this.get_help_desks_temporary_contact_details()
      this.issue = {}
      this.issueChats = []
      this.get_issue()
    },

    toggleSearchNextQueuedIssue() {
      if (!this.searchNextQueuedIssue) {
        if (this.issue.status === 'open') {
          this.$bvModal
            .msgBoxConfirm(
              'This issue is still open, are you sure that you want to search for the next issue?',
              {
                title: 'Confirm Leave Issue',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Search Next Issue',
                cancelTitle: 'Cancel',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
              },
            )
            .then(value => {
              if (value) {
                this.searchNextQueuedIssue = true
              }
            })
        } else {
          this.searchNextQueuedIssue = true
        }
      } else {
        this.searchNextQueuedIssue = false
      }
    },
  },
}
</script>

<style lang="scss">

@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
